
<div class="container">
<div class="data">
  <br>
  <br>
  <div class="upper-section">
    <div class="middle-section">
      <div>
        <h2 style="margin-top:10px">{{ title }}</h2>
      </div>
      <button
        mat-button
        [disabled]="!(currentUser?.org_permission == 'Admin' || currentUser?.org_permission == 'Creator')"
        class="create-btn"
        routerLink="/admin/meeting-creation-page"
      >
        Create Meeting
      </button>
    </div>
  </div>

 
  <br />
  <div class="filter_meetings" align="end">
    <mat-form-field appearance="standard" *ngIf="id == 'active'">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input
        [formGroup]="meetingRange"
        [min]="today"
        [rangePicker]="picker"
      >
        <input matStartDate formControlName="start_date" />
        <input matEndDate formControlName="end_date" />
      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field appearance="standard" *ngIf="id != 'active'">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input
        [formGroup]="meetingRange"
        [max]="today"
        [rangePicker]="picker"
      >
        <input matStartDate formControlName="start_date" />
        <input matEndDate formControlName="end_date" />
      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button
      mat-button
      class="search-btn"
      [disabled]="!meetingRange.valid"
      (click)="getMeetingsRange(meetingRange.value)"
    >
      <p>Search</p>
    </button>
  </div>
  <div class="content-section">
    <mat-card class="card" *ngFor="let meeting of meetingsObj" matRipple>
      <div class="heading-section">
        <div class="meeting_date_card" style="width: 10rem;">
          <span>
            {{ meeting?.start_date | date: "d" }}
          </span>
          <p>
            {{ meeting?.start_date | date: "MMM" }}
          </p>
          <p>
            {{ meeting?.start_date | date: "YYYY" }}
          </p>
        </div>
        <br>
        <div class="desc-section" (click)="viewMeetingDetails(meeting)">

          <mat-card-title class="meet-title"><span>{{ meeting?.meeting_title }}</span></mat-card-title>
          <p>{{ meeting?.start_date | date: "h:mm a" }} to {{ meeting?.end_date | date: "h:mm aa" }}</p>
          <p>
            <span>
              {{ meeting.meeting_address }}
            </span> 
            <br />
            <!-- <b *ngFor="let item of meeting.invitees"
              >{{ item?.first_name }} {{ item?.last_name }},
            </b> -->
            <button  (click)="openBook(meeting)" style="border: none;">
              <mat-icon style="color: #201547; border: none; font-size: 18px;" >library_books</mat-icon>
            </button>
          </p>
        </div>
        <div>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            class="example-icon"
            aria-label="Example icon-button with share icon"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="viewMeetingDetails(meeting)">
              <span> View meeting</span>
              <mat-icon color="primary">launch</mat-icon>
            </button>
            <button
              [disabled]="!(currentUser?.id ==  meeting.created_by.id) "
              mat-menu-item
              (click)="deleteMeeting(meeting.id)"
            >
              <span> Delete meeting</span>
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-card>
  </div>
</div>
</div>
